<template>

    <div v-if="head" class="col-12 align-left mt-5 p-3 w-100 bg-med">
        <h3 class="section-title semibold pb-2 display-4">{{ head.title }}</h3>
        <p class="section-text display-8">{{ head.description }}</p>
    </div>

    <div :class="['layout-text-aside-alt', 'px-5', 'pt-3', data.side=='left'?'bg-med':'']" id="new_1">

        <div class="layout-alt-row mt-4">

            <div v-if="data.side=='left'" :class="['wrap-image', 'align-center']">
                <div v-if="data.image">
                    <img :src="data.image" :class="['img-fluid', 'w-auto', data.glow?'glow':'', larger?'enlarge':'']" @mouseover="larger=true" @mouseleave="larger=false" width="auto" :alt="'Image'">
                    </div>
                    <div v-if="data.video">
                    <div class="">
                        <iframe width="100%" height="360px" :src="data.video" title="nuez videoshow" 
                                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen />
                    </div>
                </div>
            </div>

            <div class="wrap-text">
                <div class="line mb-3" />
                <div v-for="(line, index) in data.text" :key="index">
                    <div class="card-text d-flex flex-row justify-content-start">
                        <i :class="['iconfont', 'fas', 'fa-circle-notch', data.side=='left'?'me-4':'', data.side=='right'?'me-4':'']"></i>
                        <p class="regular display-7"> {{ line }} </p>
                    </div>
                </div>
                <div v-if="data.buttons">
                    <div v-for="(set, index) in data.buttons" :key="index" class="flex-row justify-content-center mt-4">
                        <a :href="set.link" target="_blank"><button type="button" class="btn btn-outline-modest mx-5">{{ set.title }}</button></a>
                    </div>
                </div>
            </div>

            <div v-if="data.side=='right'" :class="['wrap-image', 'align-center', 'p-2']">
                <div v-if="data.image">
                    <img :src="data.image" :class="['img-fluid', 'w-auto', data.glow?'glow':'', larger?'enlarge':'']" @mouseover="larger=true" @mouseleave="larger=false" width="auto" :alt="'Image'">
                </div>
                <div v-if="data.video">
                    <div class="">
                        <iframe width="100%" height="360px" :src="data.video" title="nuez videoshow" :class="data.glow?'glow':''"
                                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen />
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    export default({
        name: 'ImageLeft',
        props: [ 'data', 'head' ],
        data(){ return { larger:false, glow:true}}
    })
</script>
<style scoped>

.glow { box-shadow: 0 0 10px 10px rgba(255,255,255,.2); }

.layout-text-aside-alt {
    padding: 2.5% 10%;
}

.layout-text-aside-alt img {
    width: 96%;
}

.layout-text-aside-alt .iconfont {
    font-size: 1rem;
    background: linear-gradient(to left, #58551b, #454215);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.layout-text-aside-alt .enlarge {
    transform: scale(1.15);
    transition: transform 2s;
  }

.layout-text-aside-alt .alternate {
    transform: scale(1.20);
    transition: transform 2s;
  }

.layout-text-aside-alt .layout-image {
    width: auto;
}

.layout-text-aside-alt .line {
    width: 55px;
    margin-bottom: 0.5rem;
    height: 5px;
    display: inline-block;
    background: linear-gradient(to left, #58551b, #454215);
}

.layout-text-aside-alt .section-btn {
    color: #999999;
}

.layout-text-aside-alt img {
    width: 100%;
}

.layout-text-aside-alt .card-text {
    color: #999999;
}
.layout-text-aside-alt .section-text {
    color: #000000;
}

.layout-text-aside-alt .section-btn {
    color: #999999;
}

img { 
    max-height: 360px;
}

@media (orientation: landscape) {
    .layout-alt-row {
        display:flex;
        flex-direction:row;
    }

    .layout-text-aside-alt .wrap-text {
        width: 45vw;
        margin-left: auto;
        margin-right: auto;
        padding: 0 3%;
    }

    .layout-text-aside-alt .wrap-image {
        width: 45vw;
        margin: auto;
        padding: 0 3%;
    }
}

@media (orientation: portrait) {

    body {
        display: flex;
        flex-direction: column;
        align-content: center;
        font-size: .85rem;
    }

    .layout-text-aside-alt {
        display:flex;
        flex-direction:column;
        align-content: center;
        justify-content: center;
    }

}
</style>