<template>
  <div class="w-100">
    <Navbar :pagetitle='pagetitle' :navitems="navitems" @downloadCall="showDowns=!showDowns" />
    <Download v-if="showDowns" @close="showDowns=false" />
    <Aside :head='head' :data='first' />
    <Aside :data='second' />
    <div class="empty-50" />
    <Modal v-if="showModal" @close="showModal=false" />
    <Footer  @modalCall="showModal=true" />
  </div>
</template>

<script>

import Modal    from '@/components/Modal'
import Navbar   from '@/components/Navbar'
import Footer   from '@/components/Footer'
import Download from '@/components/Downloads'
import Aside    from '@/components/ImageAsideAlt'
import Mongo    from '@/assets/resized/nuez/mongo.png'

  export default ({
      name: 'MongoDB',
      components: { Aside, Download, Modal, Navbar, Footer },
      data(){ return {
        showDowns:false,
        showModal:false,
        pagetitle: 'MongoDB',
        head: { title: 'setting up a cloud atlas.', description: 'video and a couple of words..' }, 
        navitems: [
            { title: 'nuez', route: 'nuez' },
            { title: 'gui',  route: 'gui' }, 
            { title: 'settings', route: 'settings' }, 
            { title: 'mongodb',  route: 'mongodb' },
        ],
        first: {
          side: 'right',
          text: [
            'register atlas.',
            'select javascript.',
            'select your service plan.',
            'select cloud provider and region.',
            'select cluster.',
            'choose cluser name.',
            'authentication method: password.',
            'ad ip access list entry: allow access from anywhere.'
          ],
          buttons: [ { title: 'register Atlas', link: 'https://www.mongodb.com/de-de/cloud/atlas' }],
          video: 'https://www.youtube-nocookie.com/embed/-8AfUzqdL4c',
          glow: true,
        },
        second: {
          side: 'left',
          text: [
            'if you´ve set up a mongodb on your own server..',
            '..having issues with logging into your machine..',
            '..don´t you hezitate contacting me.',
            'i already coded this flexible, but.. hey!'
          ],
          image: Mongo,
          glow: false
        },
      }}
  })
</script>

<style scoped>
</style>

// https://www.youtube.com/embed/-8AfUzqdL4c